<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Each pair of carbonyl compounds listed below has a carbonyl peak occurring at a wavenumber
        close to
        <stemble-latex content="$1700\,\text{cm}^{-1}.$" />
        Based on the differences between the two molecules in each pair, determine whether or not
        the carbonyl peak would show up at a wavenumber slightly lower than
        <stemble-latex content="$1700\,\text{cm}^{-1},$" />
        or slighter higher than
        <stemble-latex content="$1700\,\text{cm}^{-1}.$" />
      </p>

      <v-simple-table>
        <thead>
          <tr>
            <th style="font-size: 15px">Molecule</th>
            <th style="font-size: 15px">
              <stemble-latex content="$<\,1700\,\text{cm}^{-1}$" />
            </th>
            <th style="font-size: 15px">
              <stemble-latex content="$>\,1700\,\text{cm}^{-1}$" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <stemble-latex content="$\text{a) 3-phenylpropanoic acid}$" />
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
                <v-radio key="lower" value="lower" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
                <v-radio key="higher" value="higher" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{a) cinnamic acid}$" />
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input2" :disabled="!allowEditing">
                <v-radio key="lower" value="lower" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input2" :disabled="!allowEditing">
                <v-radio key="higher" value="higher" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{b) but-2-enal}$" />
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input3" :disabled="!allowEditing">
                <v-radio key="lower" value="lower" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input3" :disabled="!allowEditing">
                <v-radio key="higher" value="higher" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{b) butanal}$" />
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input4" :disabled="!allowEditing">
                <v-radio key="lower" value="lower" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input4" :disabled="!allowEditing">
                <v-radio key="higher" value="higher" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{c) hept-4-en-3-one}$" />
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input5" :disabled="!allowEditing">
                <v-radio key="lower" value="lower" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input5" :disabled="!allowEditing">
                <v-radio key="higher" value="higher" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{c) heptan-3-one}$" />
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input6" :disabled="!allowEditing">
                <v-radio key="lower" value="lower" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input6" :disabled="!allowEditing">
                <v-radio key="higher" value="higher" />
              </v-radio-group>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'ChemUCI41LBA6A15',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
      },
    };
  },
};
</script>
